@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  composes: marketplaceModalInMobileBaseStyles from global;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: unset;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  composes: h2 from global;

  color: var(--marketplaceColor);
  margin: 0 24px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.author {
  composes: h4 from global;
  margin: 24px 24px 32px 24px;

  display: flex;
  flex-direction: row;
  width: 100%;

  @media (--viewportMedium) {
    margin: 24px 0 32px 0;
    color: var(--matterColor);
  }
}

.providerAvatar {
  margin-right: 10px;
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  composes: h5 from global;
  display: none;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;

  @media (--viewportMedium) {
    padding: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  composes: h2 from global;
  color: var(--matterColor);

  /* margin-top: 0;
  margin-bottom: 0; */

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderButton {
  composes: button buttonFont buttonText buttonBorders buttonColorsPrimary from global;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.category {
  margin: 0;
  color: #9aa0a6;
  font-size: 14px;
  text-transform: uppercase;

  font-family: 'hernandezbros';
}

.reviewsLabel {
  color: #9aa0a6;
  font-size: 14px;
  margin-left: 10px;
}

.reviews {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}
p{
  white-space: pre-wrap!important;
}
.description {
  margin: 0;
  /* text-transform: lowercase; */
  white-space: pre-wrap!important;
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

.priceWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.priceLabel {
  margin: 0;
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'hernandezbros';
}

.priceAmount {
  margin: 0;
  font-size: 20px;
}

.mobileOfferButton {
  width: 100%;
  margin-left: 10px;
  min-height: 50px;
  background-color: white;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
    color: white;
  }
}

.affirmLogo {
  position: relative;
  bottom: 0;
  right: 0;
  width: 70px;
  background-color: transparent;
  padding: 6px;
  /* z-index: 99; */
}

.txInfoMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.property {
  margin-top: 20px;
  margin-bottom: 20px;
}
