.root {
}

.input {
  border: 1px solid rgb(207, 207, 207);
  padding-left: 10px;
  border-radius: 5px;
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}
