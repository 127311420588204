@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  /* Layout */
  display: flex;
  flex-direction: column;

  /* border: 1px solid rgba(0, 0, 0, 0.125); */
  border-radius: 4px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    box-shadow: var(--boxShadowListingCard);
  }
}

.aspectRatioWrapper {
  transition: var(--transitionStyleButton);
  background: var(--matterColorNegative);
  /* Loading BG color */
  border-radius: 4px;
  position: relative;

  background-color: transparent;

  @media (--viewportMedium) {
    &:hover {
      /* transform: scale(1.02); */
      /* box-shadow: var(--boxShadowListingCard); */
    }
  }
}

.favButtonWrapper {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 5;
}

.badgeWrapper {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
  background-color: #b4876d;
  padding: 1px 8px;
  border-radius: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.badgeWrapper1 {
  position: absolute;
  top: 55px;
  left: 10px;
  z-index: 5;
  background-color: #b4876d;
  padding: 1px 8px;
  border-radius: 20px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  @media(max-width:798px){
    top: 42px;
  }
}
.rootForImage {
  /* border-bottom: solid 1px var(--matterColorNegative); */
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  width: 100%;
  height: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.price {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
}

.priceValue {
  /* Font */
  composes: h3 from global;
  color: var(--matterColor);
  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  min-height: 60px;
}

.title {
  /* Font */
  composes: marketplaceDefaultFontStyles from global;
  line-height: 24px;
  color: var(--matterColor);
  /* text-transform: capitalize; */
  font-family: 'inter';
}

.authorInfo {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);

  padding-top: 4px;
  padding-bottom: 4px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.category {
  margin: 0;
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'hernandezbros';
  color: var(--matterColor);
}

.listingCardBottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'hernandezbros';
}

.lastSeenDesktop {
  position: absolute;
  bottom: 10px;
  left: 5px;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #b4876d;
  color: white;
  word-wrap: break-word;
  border-radius: 20px;
  line-height: 20px;
  padding: 2px 5px;

  @media (max-width: 768px) {
    display: none;
  }
}

.lastSeenMobile {
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #b4876d;
  color: white;
  word-wrap: break-word;
  border-radius: 20px;
  line-height: 16px;
  padding: 2px 5px;
  margin-top: 10px;
  gap: 5px;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
}

.authorIcon {
  width: 40px;
  height: 40px;
  border-radius: 111px;
  object-fit: cover;
}

.authorWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
}

.authorInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
}

.soldOverlay {
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.soldLabel {
  width: 100%;
  z-index: 12;
  background-color: rgb(255, 0, 0, 0.5);
  color: white;
  text-align: center;
  font-family: 'hernandezbros';
  padding: 10px 0;
}
