@import '../../../../styles/customMediaQueries.css';


.notificationItem {
  padding: 10px 20px;
  line-height: 22px;
  margin: 10px 0;
  cursor: pointer;
  text-align: start;
  box-shadow: var(--boxShadow);
  font-size: 16px;

  &:hover {
    transform: scale(1.03);
  }
}

.notificationItemNotSeen {
  padding: 10px 20px;
  line-height: 22px;
  margin: 10px 0;
  cursor: pointer;
  text-align: start;
  box-shadow: var(--boxShadow);
  color: var(--attentionColor);
  font-size: 16px;

  &:hover {
    transform: scale(1.03);
  }
}

