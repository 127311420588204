@import '../../../styles/customMediaQueries.css';

.quantityField {
  /* hide quantity field to simulate no quantity select, in fact we have quantity: 1 as initial value */
  visibility: hidden;
  position: fixed;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.deliveryField {
  visibility: hidden;
  position: fixed;
  padding: 0 24px;
  margin-top: 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.singleDeliveryMethodSelected {
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 20px;
  }
}

.submitButton {
  padding: 0 24px;
  margin-top: 1pc;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 1pc;
  }
}

.finePrint {
  composes: marketplaceTinyFontStyles from global;
  text-align: center;
}

.error {
  composes: h4 from global;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.outOfStockWrapper {
  width: 100%;
  text-align: center;
}

.outOfStockWrapper>p {
  font-size: 25px;
  color: red;
  margin-top: 5pc;
}

.modalButtonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 2pc;
}

.modalButton {
  width: 40%;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 25px;

  @media (--viewportLarge) {
    display: none;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
}

.buttonsWrapperDesktop {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  gap: 25px;
  display: none;

  @media (--viewportLarge) {
    display: flex;
  }

  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
  }
}

.addToCartButton {
  display: none;
  flex: 1;
  min-height: 50px;
  background-color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColorDark);
    border: 1px solid var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    display: block;
  }
}

.offerButton {
  flex: 1;
  min-height: 50px;
  background-color: white;
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColor);
    border: 1px solid var(--marketplaceColor);
    color: white;

    @media (--viewportLarge) {
      display: block;
    }
  }

  /* @media (max-width: 768px) {
    display: none;
  } */

  @media (max-width: 1024px) {
    display: none;
  }
}

.deliveryInfoWrapper {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 768px) {
    padding: 0 20px;
    margin-bottom: 20px;
  }
}

.moreInfoButton {
  margin-left: 10px;
  text-decoration: underline;
  cursor: pointer;
  color: var(--marketplaceColor);
}

.shippingIcon {
  width: 40px;
  margin-right: 60px;
  height: auto;
}

.affirmLogo {
  position: relative;
  bottom: 0;
  right: 0;
  width: 100px;
  background-color: transparent;
  padding: 10px;
  /* z-index: 99; */
}

.affirmWrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: flex-start;

  @media (max-width: 768px) {
    padding: 0 20px;
    margin-bottom: 20px;
  }
}